import React, { useState } from "react";

// fontlar
import poppinsRegular from "../../../assets/fonts/Poppins-Regular.ttf";

// font import
Font.register({
  family: "Poppins",
  src: poppinsRegular,
});

// pdf tools
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
  Image,
  pdf,
  PDFViewer,
} from "@react-pdf/renderer";
import {
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 8, // Genel font boyutunu küçült
    fontFamily: "Poppins",
    position: "relative",
  },
  header: {
    textAlign: "center",
  },
  title: {
    fontSize: 14, // Başlık font boyutunu küçült
    fontWeight: "bold",
    marginBottom: 5,
    textTransform: "uppercase",
  },
  subTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 25,
    textTransform: "capitalize",
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 12, // Bölüm başlığı font boyutunu küçült
    fontWeight: "bold",
    marginTop: 5,
    marginBottom: 10,
    borderBottom: "1px solid #e63946",
    color: "#e63946",
  },
  twoColumnContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  column: {
    width: "48%",
  },
  paragraph: {
    fontSize: 10, // Paragraf font boyutunu küçült
    marginBottom: 10,
    lineHeight: 1.4, // Satır yüksekliğini küçült
  },
  signature: {
    position: "absolute",
    bottom: 40,
    right: 40,
    fontSize: 10, // İmza font boyutunu küçült
    textAlign: "right",
    borderTopWidth: 1,
    borderTopColor: "#000",
    paddingTop: 10,
  },
  footer: {
    marginTop: 30,
    textAlign: "center",
    fontSize: 8, // Footer font boyutunu küçült
    color: "#888",
  },
});

const MyDocument = ({ date, nameSurname }) => {
  return (
    <Document title="DAVRANIŞ KURALLARI">
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>DAVRANIŞ KURALLARI</Text>
          <Text style={styles.subTitle}>
            Ağız Boşluğunda Ameliyat Sonrası Davranış Kuralları
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>1. Kanama</Text>
          <View>
            <Text style={styles.paragraph}>
              Mendili yaklaşık 30 dakika boyunca ısırın. Yarayı kapatmak için
              bir protez kullanılmışsa, protezi 2-3 saat yerinde bırakın ve
              ardından kan kalıntılarını temizleyin. Bugün fiziksel efor sarf
              etmekten veya eğilmekten kaçının. Kanama devam ederse, temiz bir
              mendili 30 dakika daha ısırın. Kanama durmazsa lütfen derhal
              bizimle iletişime geçin.
            </Text>
          </View>
          <Text style={styles.sectionTitle}>2. Anestezi</Text>
          <View>
            <Text style={styles.paragraph}>
              Lokal anestezinin yanı sıra tedavinin yarattığı stres ve gerginlik
              konsantre olma ve tepki verme yeteneğinizi etkileyebilir. Araç ve
              makine kullanırken lütfen bunu aklınızda bulundurun. Ameliyattan
              sonra eve bırakılmanız gerekmektedir.
            </Text>
          </View>
          <Text style={styles.sectionTitle}>3. Yiyecek ve içecek</Text>
          <View>
            <Text style={styles.paragraph}>
              Anestezinin etkisi tamamen geçene kadar bekleyin. Kendinizi
              ısırarak veya yakarak yaralayabilirsiniz. Lütfen süt ürünleri ve
              baldan kaçının ve bugün kahve, siyah çay veya alkol içmemelisiniz.
              Önümüzdeki birkaç gün boyunca küçük tahıllı gıdalara (örneğin
              pirinç, bulgur vb.) da dikkat etmelisiniz.
            </Text>
          </View>
          <Text style={styles.sectionTitle}>4. Sigara içmek</Text>
          <View>
            <Text style={styles.paragraph}>
              Sigara içmek yaraların iyileşmesini engeller, bu nedenle lütfen en
              azından bugün ve yarın sigara içmekten kaçının.
            </Text>
          </View>
          <Text style={styles.sectionTitle}>5. Diş bakımı</Text>
          <View>
            <Text style={styles.paragraph}>
              Lütfen ağzınızı çok sık çalkalamayın, aksi takdirde yara stabilize
              olmayacaktır. Dişlerinizi düzenli olarak fırçalamaya devam edin,
              ancak önümüzdeki birkaç gün boyunca yara bölgesine fazla
              yüklenmeyin.
            </Text>
          </View>
          <Text style={styles.sectionTitle}>6. Soğutma</Text>
          <View>
            <Text style={styles.paragraph}>
              Yara bölgesini üç saat boyunca sürekli olarak soğuk, nemli
              kompreslerle soğutun. Her seferinde 15 dakika soğutun ve ardından
              5 dakika ara verin. Soğutma için buz kullanmayın!
            </Text>
          </View>
          <Text style={styles.sectionTitle}>7. Yara ağrısı</Text>
          <View>
            <Text style={styles.paragraph}>
              Lokal anestezinin etkisi geçtikten sonra hafif şişlik gibi ağrı da
              normaldir. Her ikisi de önümüzdeki birkaç gün içinde azalacaktır.
              İlk dönem için lütfen konsültasyondan sonra bir ağrı kesici alın.
              Ağrı azalmazsa, daha şiddetli hale gelirse veya sadece birkaç gün
              sonra ortaya çıkarsa, lütfen hemen bizi bilgilendirin.
            </Text>
          </View>
        </View>
        <View style={styles.signature}>
          <Text>{date}</Text>
          <Text>{nameSurname}</Text>
        </View>
      </Page>
    </Document>
  );
};

// pdf tolls **

const RulesOfConductModal = ({
  date,
  nameSurname,
  isAgreement,
  setIsAgreement,
}) => {
  const [modal_agreement, setmodal_agreement] = useState(false);

  const handleCheckboxChange = () => {
    if (isAgreement) {
      setIsAgreement(false);
    } else setmodal_agreement(true);
  };

  const handleConfirm = () => {
    setmodal_agreement(false);
    setIsAgreement(true);
  };

  function tog_agreement() {
    setmodal_agreement(!modal_agreement);
  }

  return (
    <>
      <div className="form-check mb-3">
        <Input
          className="form-check-input"
          type="checkbox"
          checked={isAgreement}
          onChange={handleCheckboxChange}
          id="approve1"
        />
        <Label className="form-check-label" htmlFor="approve1">
          Ağız boşluğunda ameliyat sonrası davranış kuralları ile ilgili
          bilgileri okudum, anladım ve bu kuralları onaylıyorum.
        </Label>
      </div>
      <Modal
        isOpen={modal_agreement}
        toggle={() => {
          tog_agreement();
        }}
        scrollable={true}
        id="agreementModal1"
        size="lg"
      >
        <ModalHeader
          className="modal-title"
          id="agreementModalTitle1"
          toggle={() => {
            tog_agreement();
          }}
        ></ModalHeader>
        <ModalBody>
          <PDFViewer width="100%" height="600px">
            <MyDocument date={date} nameSurname={nameSurname} />
          </PDFViewer>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <button
            type="button"
            className="btn w-sm btn-primary"
            id="delete-record"
            onClick={handleConfirm}
          >
            Okudum onaylıyorum!
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RulesOfConductModal;
