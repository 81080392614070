import React, { useState } from "react";

// fontlar
import poppinsRegular from "../../../assets/fonts/Poppins-Regular.ttf";

// font import
Font.register({
  family: "Poppins",
  src: poppinsRegular,
});

// pdf tools
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
  Image,
  pdf,
  PDFViewer,
} from "@react-pdf/renderer";
import {
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 8, // Genel font boyutunu küçült
    fontFamily: "Poppins",
    position: "relative",
  },
  header: {
    textAlign: "center",
  },
  title: {
    fontSize: 14, // Başlık font boyutunu küçült
    fontWeight: "bold",
    marginBottom: 5,
    textTransform: "uppercase",
  },
  subTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 25,
    textTransform: "capitalize",
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 12, // Bölüm başlığı font boyutunu küçült
    fontWeight: "bold",
    marginTop: 5,
    marginBottom: 10,
    borderBottom: "1px solid #e63946",
    color: "#e63946",
  },
  twoColumnContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  column: {
    width: "48%",
  },
  paragraph: {
    fontSize: 10, // Paragraf font boyutunu küçült
    marginBottom: 10,
    lineHeight: 1.4, // Satır yüksekliğini küçült
  },
  signature: {
    position: "absolute",
    bottom: 40,
    right: 40,
    fontSize: 10, // İmza font boyutunu küçült
    textAlign: "right",
    borderTopWidth: 1,
    borderTopColor: "#000",
    paddingTop: 10,
  },
  footer: {
    marginTop: 30,
    textAlign: "center",
    fontSize: 8, // Footer font boyutunu küçült
    color: "#888",
  },
});

const MyDocument = ({ date, nameSurname }) => {
  return (
    <Document title="ONAY – CERRAHİ MÜDAHLE">
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>ONAY – CERRAHİ MÜDAHLE</Text>
          <Text style={styles.subTitle}>Diş Ameliyatı İçin Onay</Text>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={styles.paragraph}>
              Aşağıdaki diş cerrahisi prosedürünün üzerimde uygulanmasına izin
              veriyorum.
            </Text>
            <Text style={styles.paragraph}>
              Söz konusu cerrahi prosedür hakkında bilgilendirildim.
            </Text>
          </View>
          <Text style={styles.sectionTitle}>
            Özellikle aşağıdaki hususlar bana açıklanmıştır:
          </Text>
          <View>
            <Text style={styles.paragraph}>
              Tüm cerrahi prosedürler, prosedür sırasında büyük özen
              gösterilmesine rağmen yara iyileşme bozukluğu, yara enfeksiyonu,
              kanama, hematom gibi komplikasyonlara yol açabilir.
            </Text>
            <Text style={styles.paragraph}>
              İşlem lokal anestezi altında gerçekleştirilir. Bu, anesteziye
              karşı intolerans reaksiyonlarına, enjeksiyon bölgesinde kanamaya
              ve enjeksiyon bölgesindeki sinirlerde hasara yol açabilir. Önceden
              var olan rahatsızlıklar ve genel hastalıklar iyileşme üzerinde
              olumsuz bir etkiye sahip olabilir. Bu nedenle, işlemden önce
              herhangi bir hastalığı ve ilgili ilaçları açıklamam gerektiğinin
              farkındayım.
            </Text>
            <Text style={styles.paragraph}>
              Anatomik koşullar nedeniyle, alt çene ameliyatları sırasında
              mandibular veya lingual sinirin yaralanması kesin olarak göz ardı
              edilemez. Bu durumda, dilde veya dudakta ve muhtemelen tat alma
              duyusunda geçici veya çok nadiren kalıcı bir bozulma veya his
              kaybı olabilir. Üst çene ameliyatı maksiller sinüsün açılmasıyla
              sonuçlanabilir. Bu durumda komplikasyonlar genellikle özellikle
              sıkı, muhtemelen plastik bir yara kapatma ile önlenir. Ancak, yara
              boşluğunda hematom oluşumu ve maksiller sinüs iltihabı kesin
              olarak göz ardı edilemez. Belirli prosedürler için maksiller
              sinüsün de prosedüre dahil edilmesi gerekir. Cerrahi prosedür ve
              olası komplikasyonlar (şişme, enfeksiyonlar, maksiller sinüzit)
              hakkında bilgilendirildim.
            </Text>
            <Text style={styles.paragraph}>
              Ameliyat sonrası kanama durumunda davranış, soğutma, ilaç alma,
              ağız hijyeni ve fiziksel dinlenme gibi işlem sonrası davranışlar
              hakkında bilgilendirildim ve talimat aldım. Olası komplikasyonlar
              ve bunun sonucunda ortaya çıkabilecek önemli yan etkiler de dahil
              olmak üzere planlanan prosedürüm hakkında verilen bilgileri
              anladığımı onaylıyorum. Benim için planlanan ameliyatın özel
              nitelikleri hakkında bilgilendirildim.
            </Text>
            <Text style={styles.paragraph}>
              İşlemden sonra araç kullanma kabiliyetimin sınırlı olabileceği
              konusunda bilgilendirildim. Bu nedenle bana bir bakıcının eşlik
              etmesi tavsiye edildi. Ayrıca, planlanan tıbbi prosedürün amacının
              ve seyrinin bana açıklandığını ve bu nedenle yeterince
              bilgilendirildiğimi onaylıyorum. İşlem sırasında komplikasyonlar
              nedeniyle veya tedavinin gerçekleştirilmemesi durumunda ortaya
              çıkabilecek bozukluklar hakkında kendimi bilgilendirme fırsatım
              oldu. Öngörülemeyen bulguların ortaya çıkması durumunda, tıbbi
              değerlendirmeye göre gerekli görülmesi halinde, önerilen prosedür
              kapsamı aşılabilir. Prosedür sırasında daha fazla açıklama mümkün
              olmasa ve prosedür kesintiye uğratılamasa veya ertelenemese bile.
            </Text>
            <Text style={styles.paragraph}>
              Kalan tüm sorularım cevaplandı.
            </Text>
          </View>
        </View>
        <View style={styles.signature}>
          <Text>{date}</Text>
          <Text>{nameSurname}</Text>
        </View>
      </Page>
    </Document>
  );
};

// pdf tolls **

const SurgicalInterventionModal = ({
  date,
  nameSurname,
  isAgreement,
  setIsAgreement,
}) => {
  const [modal_agreement, setmodal_agreement] = useState(false);

  const handleCheckboxChange = () => {
    if (isAgreement) {
      setIsAgreement(false);
    } else setmodal_agreement(true);
  };

  const handleConfirm = () => {
    setmodal_agreement(false);
    setIsAgreement(true);
  };

  function tog_agreement() {
    setmodal_agreement(!modal_agreement);
  }

  return (
    <>
      <div className="form-check mb-3">
        <Input
          className="form-check-input"
          type="checkbox"
          checked={isAgreement}
          onChange={handleCheckboxChange}
          id="approve3"
        />
        <Label className="form-check-label" htmlFor="approve3">
          Diş ameliyatı ile ilgili bilgileri okudum, anladım ve bu cerrahi
          müdahaleyi onaylıyorum.
        </Label>
      </div>
      <Modal
        isOpen={modal_agreement}
        toggle={() => {
          tog_agreement();
        }}
        scrollable={true}
        id="agreementModal3"
        size="lg"
      >
        <ModalHeader
          className="modal-title"
          id="agreementModalTitle3"
          toggle={() => {
            tog_agreement();
          }}
        ></ModalHeader>
        <ModalBody>
          <PDFViewer width="100%" height="600px">
            <MyDocument date={date} nameSurname={nameSurname} />
          </PDFViewer>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <button
            type="button"
            className="btn w-sm btn-primary"
            id="delete-record"
            onClick={handleConfirm}
          >
            Okudum onaylıyorum!
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SurgicalInterventionModal;
