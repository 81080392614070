import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

const initialState = {
  question: {},
  questionLoading: true,
  questionRefresh: true,

  questionList: [],
  questionListLoading: true,
  questionListRefresh: true,
};

export const fetchQuestion = createAsyncThunk(
  "question/fetchQuestion",
  async (id) => {
    try {
      const response = await baseApi.get(`/Question/GetById/${id}`);

      return response.data.result;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchQuestionList = createAsyncThunk(
  "question/fetchQuestionList",
  async () => {
    try {
      const response = await baseApi.get(`/Question/GetAll`);

      return response.data.result;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const questionSlice = createSlice({
  name: "question",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestion.pending, (state, action) => {
        state.question = {};
        state.questionLoading = true;
      })
      .addCase(fetchQuestion.fulfilled, (state, action) => {
        state.question = action.payload;
        state.questionLoading = false;
      })
      .addCase(fetchQuestion.rejected, (state, action) => {
        state.question = {};
        state.questionLoading = false;
      });

    builder
      .addCase(fetchQuestionList.pending, (state, action) => {
        state.questionList = [];
        state.questionListLoading = true;
      })
      .addCase(fetchQuestionList.fulfilled, (state, action) => {
        state.questionList = action.payload;
        state.questionListLoading = false;
      })
      .addCase(fetchQuestionList.rejected, (state, action) => {
        state.questionList = [];
        state.questionListLoading = false;
      });
  },
});

export default questionSlice.reducer;
