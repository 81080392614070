import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

import { Bounce, toast } from "react-toastify";

const initialState = {
  question: {},
  questionLoading: true,
  questionRefresh: true,

  isPatientPresent: null,
  checkPatientLoading: false,
};

export const checkPatientTCKN = createAsyncThunk(
  "user/checkPatientTCKN",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const model = {
      name: values.name,
      surname: values.surname,
      tckn: values.tckn,
    };

    try {
      const response = await baseApi.post(`/User/CheckPatientTCKN`, model);

      return response.data.result;
    } catch (error) {
      throw new Error(error.response.data.errorMessages);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkPatientTCKN.pending, (state, action) => {
        state.isPatientPresent = null;
        state.checkPatientLoading = true;
      })
      .addCase(checkPatientTCKN.fulfilled, (state, action) => {
        state.isPatientPresent = action.payload;
        state.checkPatientLoading = false;
      })
      .addCase(checkPatientTCKN.rejected, (state, action) => {
        state.isPatientPresent = null;
        state.checkPatientLoading = false;

        toast.error(action.error.message, {
          style: { background: "#e30a17" },
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
  },
});

export default userSlice.reducer;
