import React, { useState } from "react";

// fontlar
import poppinsRegular from "../../../assets/fonts/Poppins-Regular.ttf";

// font import
Font.register({
  family: "Poppins",
  src: poppinsRegular,
});

// pdf tools
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
  Image,
  pdf,
  PDFViewer,
} from "@react-pdf/renderer";
import {
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 8, // Genel font boyutunu küçült
    fontFamily: "Poppins",
    position: "relative",
  },
  header: {
    textAlign: "center",
  },
  title: {
    fontSize: 14, // Başlık font boyutunu küçült
    fontWeight: "bold",
    marginBottom: 5,
    textTransform: "uppercase",
  },
  subTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 25,
    textTransform: "capitalize",
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 12, // Bölüm başlığı font boyutunu küçült
    fontWeight: "bold",
    marginTop: 5,
    marginBottom: 10,
    borderBottom: "1px solid #e63946",
    color: "#e63946",
  },
  twoColumnContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  column: {
    width: "48%",
  },
  paragraph: {
    fontSize: 10, // Paragraf font boyutunu küçült
    marginBottom: 10,
    lineHeight: 1.4, // Satır yüksekliğini küçült
  },
  signature: {
    position: "absolute",
    bottom: 40,
    right: 40,
    fontSize: 10, // İmza font boyutunu küçült
    textAlign: "right",
    borderTopWidth: 1,
    borderTopColor: "#000",
    paddingTop: 10,
  },
  footer: {
    marginTop: 30,
    textAlign: "center",
    fontSize: 8, // Footer font boyutunu küçült
    color: "#888",
  },
});

const MyDocument = ({ date, nameSurname }) => {
  return (
    <Document title="ONAY - IMPLANTASYON">
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>ONAY - IMPLANTASYON</Text>
          <Text style={styles.subTitle}>
            Yapay diş kökü implantasyonu için onay (İmplant)
          </Text>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={styles.paragraph}>
              Yapay bir diş kökünün (implant) implantasyonu için onay veriyorum.
              İlgili cerrahi prosedür hakkında bilgilendirildim.
            </Text>
          </View>
          <Text style={styles.sectionTitle}>
            Özellikle aşağıdaki hususlar bana açıklanmıştır:
          </Text>
          <View>
            <Text style={styles.paragraph}>
              Her cerrahi işlemde olduğu gibi bir operasyon olan implantasyon,
              işlem sırasında gösterilen tüm özene rağmen yara iyileşme
              bozuklukları, yara enfeksiyonu, kanama ve hematom gibi
              komplikasyonlara yol açabilir. İşlem lokal anestezi altında
              gerçekleştirilir. Bu durum anesteziye karşı tahammülsüzlük
              reaksiyonlarına, enjeksiyon bölgesinde kanamaya ve enjeksiyon
              bölgesindeki sinirlerde hasara yol açabilir. Önceden var olan
              rahatsızlıklar ve genel hastalıklar implantın iyileşmesini olumsuz
              etkileyebilir. Bu nedenle, işlemden önce herhangi bir hastalığı ve
              buna bağlı ilaçları açıklamam gerektiğinin de farkındayım.
              İmplantlar genellikle kemikte çok iyi iyileşir. Ancak başarı
              garanti edilemeyeceği gibi implantın belirli bir süre boyunca
              yerinde kalması da mümkün değildir.
            </Text>
            <Text style={styles.paragraph}>
              Alt çeneye implantasyon durumunda, anatomik koşullar nedeniyle
              çene veya lingual sinirin yaralanması kesin olarak göz ardı
              edilemez. Bu durumda, dudakta veya dilde ve muhtemelen tat alma
              duyusunda geçici veya çok nadiren kalıcı bir bozulma veya his
              kaybı olabilir. Üst çeneye yapılan implantasyon maksiller sinüsün
              açılmasına neden olabilir. Kural olarak, plastik ve özellikle sıkı
              bir yara kapatma ile komplikasyonlar önlenir. Ancak, yara
              boşluğunda hematom oluşumu ve maksiller sinüsün olası
              iltihaplanması kesin olarak göz ardı edilemez.
            </Text>
            <Text style={styles.paragraph}>
              Belirli prosedürler için maksiller sinüsün de prosedüre dahil
              edilmesi gerekiyor. Cerrahi prosedür ve buna bağlı olası
              komplikasyonlar (şişme, enfeksiyonlar, maksiller sinüzit) hakkında
              bilgilendirildim. Ayrıca implantların çok iyi bir diş bakımı
              gerektirdiği konusunda da bilgilendirildim. Bir implantasyondan
              sonra düzenli kontroller gereklidir. İmplantın takılmasından
              sonraki ilk yıl içinde üç ayda bir kontrolün gerekli olduğu,
              ardından abutmentin iltihaplanması veya gevşemesinden kaynaklanan
              kayıpları önlemek için profesyonel diş temizliği (profilaksi) ile
              birlikte altı ayda bir düzenli kontrolün gerekli olduğu konusunda
              bilgilendirildim.
            </Text>
            <Text style={styles.paragraph}>
              Benim için planlanan prosedür hakkında, olası komplikasyonlar ve
              ortaya çıkan önemli bozukluklar da dahil olmak üzere verilen
              bilgileri anladığımı onaylıyorum. Benim için planlanan
              implantların özellikleri hakkında bilgilendirildim. Sonuç olarak,
              alternatif tedavi seçenekleri - implantlar olmadan da -
              tartışıldı.
            </Text>
            <Text style={styles.paragraph}>
              İşlemden sonra araç kullanma kabiliyetimin kısıtlanabileceği
              konusunda bilgilendirildim. Bu nedenle bana bir bakıcının eşlik
              etmesi önerildi. Planlanan tıbbi prosedürün amaçları ve seyri
              hakkında bilgilendirildiğimi onaylıyorum. İşlem sırasında
              komplikasyonlar nedeniyle veya tedavinin gerçekleştirilmemesi
              durumunda ortaya çıkabilecek bozukluklar hakkında kendimi
              bilgilendirme fırsatım oldu. Öngörülemeyen bulguların ortaya
              çıkması durumunda, tıbbi değerlendirmeye göre gerekli görülürse,
              işlem sırasında daha fazla bilgi verilemese ve işlem kesintiye
              uğratılamasa veya ertelenemese bile, önerilen işlem kapsamı
              aşılabilir.
            </Text>
            <Text style={styles.paragraph}>Tüm sorularım cevaplandı.</Text>
          </View>
        </View>
        <View style={styles.signature}>
          <Text>{date}</Text>
          <Text>{nameSurname}</Text>
        </View>
      </Page>
    </Document>
  );
};

// pdf tolls **

const ImplantModal = ({ date, nameSurname, isAgreement, setIsAgreement }) => {
  const [modal_agreement, setmodal_agreement] = useState(false);

  const handleCheckboxChange = () => {
    if (isAgreement) {
      setIsAgreement(false);
    } else setmodal_agreement(true);
  };

  const handleConfirm = () => {
    setmodal_agreement(false);
    setIsAgreement(true);
  };

  function tog_agreement() {
    setmodal_agreement(!modal_agreement);
  }

  return (
    <>
      <div className="form-check mb-3">
        <Input
          className="form-check-input"
          type="checkbox"
          checked={isAgreement}
          onChange={handleCheckboxChange}
          id="approve2"
        />
        <Label className="form-check-label" htmlFor="approve2">
          Yapay diş kökü implantasyonu (implant) ile ilgili bilgileri okudum,
          anladım ve bu implantasyonu onaylıyorum.
        </Label>
      </div>
      <Modal
        isOpen={modal_agreement}
        toggle={() => {
          tog_agreement();
        }}
        scrollable={true}
        id="agreementModal2"
        size="lg"
      >
        <ModalHeader
          className="modal-title"
          id="agreementModalTitle2"
          toggle={() => {
            tog_agreement();
          }}
        ></ModalHeader>
        <ModalBody>
          <PDFViewer width="100%" height="600px">
            <MyDocument date={date} nameSurname={nameSurname} />
          </PDFViewer>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <button
            type="button"
            className="btn w-sm btn-primary"
            id="delete-record"
            onClick={handleConfirm}
          >
            Okudum onaylıyorum!
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ImplantModal;
