import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import withRouter from "../../Components/Common/withRouter";

import NewUserImg from "../../assets/images/dent/newuser.png";
import OldUserImg from "../../assets/images/dent/olduser.png";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div
      className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"
      style={{
        background: "#FDFDFD",
      }}
    >
      <div className="auth-page-content overflow-hidden pt-lg-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <div className="text-center mb-5">
                <h1>LOGO</h1>
              </div>
              <Card
                className="card-animate"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/new-patient")}
              >
                <CardBody>
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <h2 className="mt-4 ff-secondary fw-semibold">
                        Yeni Hasta Girişi
                      </h2>
                    </div>
                    <img
                      src={NewUserImg}
                      className="me-3 rounded-circle avatar-md p-2 bg-light"
                      alt="newUser"
                    />
                  </div>
                </CardBody>
              </Card>
              <Card
                className="card-animate"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/patient-login")}
              >
                <CardBody>
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <h2 className="mt-4 ff-secondary fw-semibold">
                        Hasta Girişi
                      </h2>
                      <p className="fw-semibold text-muted fs-11">
                        *Daha önce muayene kaydı olan hastalarımız için.
                      </p>
                    </div>
                    <img
                      src={OldUserImg}
                      className="me-3 rounded-circle avatar-md p-2 bg-light"
                      alt="oldUser"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <footer className="footer">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <p className="mb-0" style={{ color: "#808080" }}>
                  &copy; {new Date().getFullYear()} BlaBla{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Yusuf Ayaz
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default withRouter(Home);
