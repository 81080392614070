import React from "react";

import Home from "../pages/Home/Home";
import PatientRegister from "../pages/PatientRegister/index.js";
import PatientEntrance from "../pages/PatientEntrance/index.js";

const publicRoutes = [
  // Authentication Page
  { path: "/", component: <Home /> },
  { path: "/new-patient", component: <PatientRegister /> },
  { path: "/patient-login", component: <PatientEntrance /> },
];

export { publicRoutes };
